export const getStorage = (key) => localStorage.getItem(key);

export const setStorage = (key, value) => localStorage.setItem(key, value);

export const removeStorage = (key) => localStorage.removeItem(key);

export const clearStorage = (key) => localStorage.clear();

export const getJsonStorage = (key) => JSON.parse(localStorage.getItem(key));

export const setJsonStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));