import { useEffect, useState, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from '../utils/api';
import { getStorage } from '../utils/localStorage';
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';

const Promotions = () => {
    const file = useRef();

    const [data, setData] = useState([]);
    const [fields, setFields] = useState({
        title: '',
        link: '',
        type: 'horizontal',
        file: ''
    });

    const fetchData = async () => {
        const response = await fetch(`${API}/api/promotions/get`, {
            method: 'POST',
            headers: {
                'x-access-token': getStorage('token')
            }
        });

        if (response.status === 200) {
            const responseJson = await response.json();
            console.log(responseJson.data.promotions);
            setData(responseJson.data.promotions);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const handleClear = (id) => async () => {
        const response = await fetch(`${API}/api/promotions/remove`, {
            method: 'POST',
            headers: {
                'x-access-token': getStorage('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({ id })
        });

        if (response.status === 200) {
            const responseJson = await response.json();
            if (responseJson.status === 200) {
                fetchData();
                alert('Se eliminó la promoción correctamente');
            } else {
                alert("Se ha producido un error");
                console.error(JSON.stringify(responseJson));
            }
        }
    }

    const handleFile = ({ target }) => {
        setFields({ ...fields, file: target.files[0] });
    };

    const handleChange = (e) => {
        setFields({ ...fields, [e.target.name]: e.target.value });
    }

    const handleSave = async () => {
        if (!fields.title.length || !fields.type.length) {
            alert('El título y el tipo son obligatorios');
        }

        const formData = new FormData();
        Object.keys(fields).forEach((param) => {
            formData.append(param, fields[param]);
        });

        const response = await fetch(`${API}/api/promotions/create-${fields.type}`, {
            method: 'POST',
            headers: {
                'x-access-token': getStorage('token'),
                'Access-Control-Allow-Origin': '*',
                'Accept-Encoding': 'gzip, compress, br',
            },
            body: formData
        });

        if (response.status === 200) {
            const responseJson = await response.json();
            if (responseJson.status === 200) {
                fetchData();
                alert('Se creó la promoción correctamente');
                setFields({
                    title: '',
                    link: '',
                    type: 'horizontal',
                    file: ''
                })
            } else {
                alert("Se ha producido un error");
                console.error(JSON.stringify(responseJson));
            }
        }
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: '300px' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Titulo</TableCell>
                            <TableCell align="left">Imagen</TableCell>
                            <TableCell align="left">Destino</TableCell>
                            <TableCell align="left">Tipo</TableCell>
                            <TableCell align="left">Accion</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.title}
                                </TableCell>
                                <TableCell align="left">{row.src}</TableCell>
                                <TableCell align="left">{row.link}</TableCell>
                                <TableCell align="left">{row.type}</TableCell>
                                <TableCell align="left"><Button onClick={handleClear(row.id)}><Clear /></Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <TextField name="title" style={{ flex: 1, width: '100%', marginTop: 10, marginBottom: 10 }} id="outlined-basic" label="Título*" variant="outlined" value={fields.title} onChange={handleChange} />
            <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
                <InputLabel id="demo-simple-select-label">Tipo de promoción*</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="type"
                    value={fields.type}
                    label="Tipo de promoción*"
                    onChange={handleChange}
                >
                    <MenuItem value='horizontal'>Horizontal</MenuItem>
                    <MenuItem value='vertical'>Vertical</MenuItem>
                </Select>
            </FormControl>
            <TextField name="link" style={{ flex: 1, width: '100%', marginTop: 10, marginBottom: 10 }} id="outlined-basic" label="Destino / Enlace / Link (Opcional)" variant="outlined" value={fields.link} onChange={handleChange} />
            <input
                style={{ marginTop: 10, marginBottom: 10, display: 'block' }}
                ref={file}
                type="file"
                aria-label='Imagen'
                name="file"
                onChange={handleFile}
                accept=".jpg, .jpeg, .png, .webp"
                size={1}
            />
            <Button style={{ marginTop: 10 }} variant="contained" onClick={handleSave}>Crear promoción</Button>
        </>
    );
}

export default Promotions;
