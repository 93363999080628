import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from '../utils/api';
import { getStorage } from '../utils/localStorage';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, IconButton, TableFooter, TablePagination, TextField, useTheme } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import LastPageIcon from '@mui/icons-material/LastPage';
import Clear from '@mui/icons-material/Clear';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / 15) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="Avanzar"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="Volver"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / 15) - 1}
                aria-label="Siguiente pagina"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / 15) - 1}
                aria-label="Ultima pagina"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const Users = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState('');
    const [open, setOpen] = useState({});

    const fetchData = async () => {
        const response = await fetch(`${API}/api/users/get`, {
            method: 'POST',
            headers: {
                'x-access-token': getStorage('token')
            }
        });

        if (response.status === 200) {
            const responseJson = await response.json();
            setData(responseJson.data);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleClear = () => {
        setPage(0);
        setFilter('');
    }

    const handleKey = (e) => {
        if (e.keyCode === 13) {
            setPage(0);
        }
    }

    const handleChange = (e) => {
        setFilter(e.target.value);
        if (page !== 0) {
            setPage(0);
        }
    }

    const handleClose = () => {
        setOpen({});
    };

    const handleSave = async () => {
        const response = await fetch(`${API}/api/users/modify`, {
            method: 'POST',
            headers: {
                'x-access-token': getStorage('token'),
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify(open)
        });

        if (response.status === 200) {
            const responseJson = await response.json();
            if (responseJson.status === 200) {
                fetchData();
                setOpen({});
                alert('Se actualizó el usuario correctamente');
            }else{
                alert('Se ha producido un error');
            }
        }
    }

    const handleOpenChange = (e) => {
        setOpen({ ...open, [e.target.name]: e.target.value });
    }

    const handleOpenChangeChecked = (e) => {
        setOpen({ ...open, [e.target.name]: e.target.checked });
    }

    const FilterData = (user) => {
        if (!filter.length) return true;

        return user.username.includes(filter) ||
            (user.name && user.name.toLowerCase().includes(filter.toLowerCase())) ||
            (user.email && user.email.toLowerCase().includes(filter.toLowerCase())) ||
            (user.phone && user.phone.includes(filter)) ||
            (user.document && user.document.includes(filter))
    }

    const handleOpen = (user) => () => {
        setOpen(user);
    }

    return (
        <>
            <Box style={{ display: 'flex', }}>
                <TextField onKeyDown={handleKey} style={{ flex: 1, width: '100%' }} id="outlined-basic" label="Buscar por algun atributo" variant="outlined" value={filter} onChange={handleChange} />
                <Button onClick={handleClear}><Clear /></Button>
            </Box>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: '300px' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Identificador</TableCell>
                            <TableCell align="left">Nombre</TableCell>
                            <TableCell align="left">Correo</TableCell>
                            <TableCell align="left">Celular</TableCell>
                            <TableCell align="left">Documento</TableCell>
                            <TableCell align="left">Token</TableCell>
                            <TableCell align="left">Acción</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.filter(FilterData).slice(page * 15, page * 15 + 15).map((row) => (
                            <TableRow
                                key={row.username}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.username}
                                </TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="left">{row.phone}</TableCell>
                                <TableCell align="left">{row.document || row.username}</TableCell>
                                <TableCell style={{
                                    maxWidth: 300,
                                    borderStyle: "border-box",
                                    overflowWrap: "anywhere"
                                }} align="left">{row.deviceToken || 'No tiene'}</TableCell>
                                <TableCell align="left"><Button onClick={handleOpen(row)}><EditIcon /></Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={3}
                                count={data.length}
                                rowsPerPage={15}
                                page={page}
                                onPageChange={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                                rowsPerPageOptions={[]}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Dialog open={!!Object.keys(open).length} onClose={handleClose}>
                <DialogTitle>Usuario</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Datos del usuario
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nombre"
                        fullWidth
                        variant="standard"
                        onChange={handleOpenChange}
                        value={open.name}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Correo"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={handleOpenChange}
                        value={open.email}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="phone"
                        label="Celular"
                        type="number"
                        fullWidth
                        variant="standard"
                        onChange={handleOpenChange}
                        value={open.phone}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="document"
                        label="Documento"
                        type="number"
                        fullWidth
                        variant="standard"
                        onChange={handleOpenChange}
                        value={open.document}
                    />
                    <FormControlLabel control={<Checkbox name="verified" checked={!!open.verified} onChange={handleOpenChangeChecked} />} label="Verificado" />
                    <FormControlLabel control={<Checkbox name="active" checked={!!open.active} onChange={handleOpenChangeChecked} />} label="Activo" />
                    <FormControlLabel control={<Checkbox name="updated" checked={!!open.updated} onChange={handleOpenChangeChecked} />} label="Datos actualizados" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cerrar</Button>
                    <Button onClick={handleSave}>Guardar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Users;
