import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { API } from '../utils/api';
import { getStorage } from '../utils/localStorage';
import { Box } from '@mui/system';
import { Button, Grid, TextField } from '@mui/material';

const Notifications = () => {
    const [data, setData] = useState({
        token: '',
        title: '',
        multiline: '',
        link: '',
        imageUrl: ''
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }

    const handleSend = async () => {
        if (data.title && data.multiline) {
            const response = await fetch(`${API}/api/firebase/send`, {
                method: 'POST',
                headers: {
                    'x-access-token': getStorage('token'),
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(data)
            });

            if (response.status === 200) {
                const responseJson = await response.json();
                if (responseJson.status === 200) {
                    alert('Se envió la notificación correctamente');
                    setData({
                        token: '',
                        title: '',
                        multiline: '',
                        link: '',
                        imageUrl: ''
                    })
                } else {
                    alert('Se ha producido un error');
                    console.error(JSON.stringify(responseJson));
                }
            }
        } else {
            alert('Los campos título y mensaje son obligatorios');
        }
    }

    return (
        <>
            <TextField name="token" style={{ flex: 1, width: '100%', marginTop: 10, marginBottom: 10 }} id="outlined-basic" label="Ingresar token (Opcional / Si queda vacío, se mandará a todos)" variant="outlined" value={data.token} onChange={handleChange} />
            <TextField name="title" style={{ flex: 1, width: '100%', marginTop: 10, marginBottom: 10 }} id="outlined-basic" label="Título*" variant="outlined" value={data.title} onChange={handleChange} />
            <TextField name="multiline" style={{ flex: 1, width: '100%', marginTop: 10, marginBottom: 10 }} id="outlined-basic" label="Mensaje*" multiline rows={4} variant="outlined" value={data.multiline} onChange={handleChange} />
            <TextField name="imageUrl" style={{ flex: 1, width: '100%', marginTop: 10, marginBottom: 10 }} id="outlined-basic" label="URL Imagen (Opcional)" variant="outlined" value={data.link} onChange={handleChange} />
            <TextField name="link" style={{ flex: 1, width: '100%', marginTop: 10, marginBottom: 10 }} id="outlined-basic" label="Enlace (Opcional)" variant="outlined" value={data.link} onChange={handleChange} />
            <Button style={{ marginTop: 10 }} variant="contained" onClick={handleSend}>Enviar notificacion</Button>
        </>
    );
}

export default Notifications;