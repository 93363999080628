import React from "react";
import {
    BrowserRouter,
    Route,
    Routes,
    Outlet
} from "react-router-dom";
import Dashboard from "../layouts/Dashboard";
import Notifications from "../pages/Notifications";
import Promotions from "../pages/Promotions";

import Signin from '../pages/Signin';
import Users from '../pages/Users';


const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Outlet />}>
                    <Route index element={<Signin />} />
                    <Route path='/dashboard' element={<Dashboard />}>
                        <Route path="/dashboard/users" element={<Users />} />
                        <Route path="/dashboard/notifications" element={<Notifications />} />
                        <Route path="/dashboard/promotions" element={<Promotions />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

const Component = ({ value }) => {
    return <p>{value}</p>
}

export default Router